import React from 'react';

import './global.css';

import Routes from './routes'

function App() {

  return (
    <Routes />
  );
}

export default App;
